import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Professeur } from '../Professeur/Professeur';

@Injectable({
  providedIn: 'root'
})

export class ProfesseurService {
  url = 'https://staff.vie-ecoles.com/server/professeurs/';
  options = { params: new HttpParams({ fromString: 'token=' + JSON.parse(localStorage.getItem('user')).token }) };

  constructor(private http: HttpClient) { }

  add(table: string, body: Professeur): Observable<string> {
    return this.http.post<string>(this.url + table, body, this.options)
      .pipe(catchError(this.handleError));
  }

  put(table: string, body: Professeur): Observable<string> {
    return this.http.put<string>(this.url + table, body, this.options)
      .pipe(catchError(this.handleError));
  }

  update(table: string, id: string, body: Professeur): Observable<string> {
    return this.http.put<string>(this.url + table + '/' + id, body, this.options)
      .pipe(catchError(this.handleError));
  }

  delete(table: string, id): Observable<string> {
    return this.http.delete<string>(this.url + table + '/' + id, this.options)
      .pipe(catchError(this.handleError));
  }

  list(table: string): Observable<Professeur[]> {
    return this.http.get<Professeur[]>(this.url + table, this.options)
      .pipe(catchError(this.handleError));
  }

  view(table: string, id: string = null): Observable<Professeur> {
    if (id) {
      return this.http.get<Professeur>(this.url + table + '/' + id, this.options)
        .pipe(catchError(this.handleError));
    } else {
      return this.http.get<Professeur>(this.url + table, this.options)
        .pipe(catchError(this.handleError));
    }
  }

  views(table: string, id: string): Observable<Professeur[]> {
    return this.http.get<Professeur[]>(this.url + table + '/' + id, this.options)
      .pipe(catchError(this.handleError));
  }
  deuxArg(table: string, id: string, id2: string): Observable<Professeur> {
    return this.http.get<Professeur>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.handleError));
  }

  deuxArgs(table: string, id: string, id2: string): Observable<Professeur[]> {
    return this.http.get<Professeur[]>(this.url + table + '/' + id + '/' + id2, this.options)
      .pipe(catchError(this.handleError));
  }

  troisArg(table: string, id: string, id2: string, id3: string): Observable<Professeur> {
    return this.http.get<Professeur>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.handleError));
  }

  troisArgs(table: string, id: string, id2: string, id3: string): Observable<Professeur[]> {
    return this.http.get<Professeur[]>(this.url + table + '/' + id + '/' + id2 + '/' + id3, this.options)
      .pipe(catchError(this.handleError));
  }

  upload(table: string, formData): Observable<any> {
    return this.http.post<any>(this.url + table, formData, this.options)
      .pipe(catchError(this.handleError));
  }


  private handleError(error: HttpErrorResponse): Observable<never> {
    let response = '';
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      response = `Une erreur s'est produite: ${error.error}`;
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error.message);
      response = `${error.error.message}`;
    }
    // Return an observable with a user-facing error message.
    return throwError(response);
  }
}
