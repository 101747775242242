<h1>Séances</h1>
<div *ngIf="s">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/home">Tableau de bord</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/seances">Séances</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/{{user.role}}/seances">{{s.classe_nom}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link disabled" tabindex="-1" aria-disabled="true"> {{s.matiere_nom}} du {{s.debut | date}} </a>
        </li>
    </ul>
    <div class="alert alert-danger" *ngIf="errorMsg"> {{errorMsg}} </div>
    <div class="recherche">
        <div class="row">
            <div class="col-lg-4">
                <h4>
                    {{s.classe_nom}}
                    <br> <small>{{s.matiere_nom}}</small>
                </h4>
            </div>
            <div class="col-lg-4">
                <h4> {{s.profNom}} {{s.profPrenoms}}
                    <small> Enseignant </small>
                </h4>
            </div>
            <div class="col-lg-4">
                <h4>
                    {{s.debut | date:'fullDate'}}
                    <br><small>{{s.debut | date:'shortTime'}}-{{s.fin | date:'shortTime'}}</small>
                </h4>
            </div>

        </div>
    </div>
    <div class="row">
      <!-- A NE PAS SUPPRIMER -->
        <!-- <div class="col-md-4">
            <div class="cadre">
                <h5>Présence sur site</h5>
                <fa-icon [icon]="s.pointage == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.pointage == 0?'error':'success' "> </fa-icon>
                <p>
                    <span *ngIf="s.pointage == 1; else noPoint">Vous êtes présent sur le site</span>
                    <ng-template #noPoint>Vous êtes absent sur le site</ng-template>
                </p>
            </div>
        </div> -->
        <div class="col-md-12">
            <div class="cadre">
                <h5>Présence en classe (Appel numérique)</h5>
                <fa-icon [icon]="s.presence == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.presence == 0 ?'error':'success' "> </fa-icon>
                <p *ngIf="s.appelDate">
                    {{s.appelDate | date:'medium'}}
                </p>
                <div *ngIf="user.role === 'professeur' && s.debut <= (today | date: 'yyyy-MM-dd HH:mm:ss')">
                  <div *ngIf="s.fin <= (today | date: 'yyyy-MM-dd HH:mm:ss'); else noPresent">
                    <!-- A NE PAS SUPPRIMER -->
<!--
                        <div *ngIf="isCameraExist; else noCameraExist">
                      <div style="text-align:center">
                          <div class="btn-group">
                              <button class="button" (click)="onOffWebCame()">Ouverture ou Fermeture de camera</button>
                              <button class="button" (click)="takeSnapshot()">Prise de Photo</button>
                          </div> <br />
                          <webcam [height]="500" [width]="1000" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                              *ngIf="showWebcam" [switchCamera]="nextWebcamObservable">
                          </webcam>
                      </div>
                          <div *ngIf="errors.length > 0">
                              <h4>Error Messages:</h4>
                              <ul *ngFor="let error of errors">
                                  <li>{{ error | json }}</li>
                              </ul>
                          </div>
                       </div> -->
                  </div>
                    <!-- <ng-template #noPresent>La séance a expiré</ng-template> -->
                    <ng-template #noPresent>La séance est en cours</ng-template>
                </div>
                <!-- A NE PAS SUPPRIMER -->

                <!-- <ng-template #noCameraExist>
                  La Camera n'est pas disponible
                </ng-template> -->

                <!-- <div class="webcam-image" *ngIf="webcamImage">
                      <img [src]="webcamImage.imageAsDataUrl" />
                      <p><strong>Note:</strong>La position de l'employé.</p>
                      <p>Latitude: {{ locationData.latitude }}</p>
                      <p>Longitude: {{ locationData.longitude }}</p>
                      <br><a *ngIf="afficherLien" routerLink="/{{user.role}}/seances/appel/{{s.idseance}}/{{s.idclasse}}">Faire l'appel numérique </a><br>
                </div> -->

                <br><a *ngIf="afficherLien && s.debut <= (today | date: 'yyyy-MM-dd HH:mm:ss') && s.fin >= (today | date: 'yyyy-MM-dd HH:mm:ss'); else nePasAfficher" routerLink="/{{user.role}}/seances/appel/{{s.idseance}}/{{s.idclasse}}" class="btn btn-danger">Faire l'appel numérique </a><br>
                <ng-template #nePasAfficher>
                    <h6 class="text-danger" *ngIf="s.fin <= (today | date: 'yyyy-MM-dd HH:mm:ss'); else noPresent">
                      Vous ne pouvez pas faire l'appel numérique pour cette séance car elle a déjà expiré.
                    </h6>
                  <ng-template #noPresent>
                    <h6 class="alert alert-warning fw-bold">
                      Vous ne pouvez pas faire l'appel numérique pour cette séance car elle n'a pas encore débutée.
                    </h6>
                  </ng-template>
                </ng-template>
                <!-- <p *ngIf="user.role != 'professeur'">
                  <a routerLink="/{{user.role}}/seances/appel/{{s.idseance}}/{{s.idclasse}}" *ngIf="s.fin <= (today | date: 'yyyy-MM-dd HH:mm:ss'); else noPresent">Faire l'appel numérique </a>
                   <ng-template #noPresent>La séance a expiré</ng-template>
                </p> -->
            </div>
        </div>
        <!-- A NE PAS SUPPRIMER -->
        <!-- <div class="col-md-4">
            <div class="cadre">
                <h5>Cahier de texte renseigné</h5>
                <fa-icon [icon]="s.cahierTexte == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="s.cahierTexte == 0 ?'error':'success' "> </fa-icon>
                <p *ngIf="user.role === 'professeur' && s.cahierTexte == 0">
                    <a *ngIf="(s.debut | date:'Y-MM-dd' ) === (today | date: 'Y-MM-dd'); else noRenseigner" routerLink="/{{user.role}}/cahiers-textes/add/{{s.idseance}}">Renseigner </a>
                    <ng-template #noRenseigner>Veuillez contacter le directeur</ng-template>
                </p>

            </div>
        </div> -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <h3 *ngIf="cahiers.length === 0" class="errorLoading text-center">
                    Cahier de texte non renseigné
                </h3>
                <div *ngFor="let cahier of cahiers">
                    <h4>
                        <app-seances-lecons [id]="cahier.lecon_id"></app-seances-lecons>
                    </h4>
                    <app-seances-lecons-titres [id]="cahier.idcahier"></app-seances-lecons-titres>

                    <hr>
                    <h5> </h5>
                    <!--p><a href="/cahier.fichier">Résumé de cours</a></p-->
                </div>
            </div>
            <div class="col-md-4" *ngIf="eleves">

                <h5>Appel numérique</h5>
                <table class="table table-bordered table-sm" *ngIf="s.presence == 1">
                    <thead>
                        <th scope="col">Nom et prenoms</th>
                        <th scope="col">Statut</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let eleve of eleves">
                            <td> {{eleve.nom}} {{eleve.prenoms}} </td>
                            <td>
                                <fa-icon [icon]="eleve.presence == 0 ? faError : faSuccess" flip="horizontal" pull="center" [class]="eleve.presence == 0 ?'error':'success' "> </fa-icon>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loading" class="text-center">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Veuillez patienter ...
</div>

