<div class="hamburger toggle-btn">
  <div class="toggle-btn" (click)="onToggleMenu()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</div>
<nav id="sidebar" class="sidebar" [ngClass]="toggle ? 'sidebar-close' :  'sidebar-open'">
  <div class="logo">
    <!--<img src="assets/images/logo-menu.png" alt="Vie-Ecoles"> -->
  </div>
  <div class="user">
    <a class="" href="" class=""><span></span> {{user.name}} </a>
    <small> {{user.role}} | {{user.code | uppercase}} </small>
  </div>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/home" routerLinkActive="active" (click)="onToggleMenu()">Tableau de
        Bord</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/seances" routerLinkActive="active" (click)="onToggleMenu()">Séances
        de cours</a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/cahiers-textes" routerLinkActive="active"
        (click)="onToggleMenu()">Cahiers de textes</a>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/evaluations" routerLinkActive="active"
        (click)="onToggleMenu()">Évaluations</a>
    </li> -->
    <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/notes" routerLinkActive="active" (click)="onToggleMenu()">Notes et
        moyennes</a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/absences" routerLinkActive="active"
        (click)="onToggleMenu()">Absences</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/sanctions" routerLinkActive="active"
        (click)="onToggleMenu()">Sanctions</a>
    </li>

    <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/{{user.role}}/progressions" routerLinkActive="active"
        (click)="onToggleMenu()">Progression nationale</a>
    </li> -->


    <li class="nav-item">
      <a class="nav-link" (click)="logout()">Deconnexion</a>
    </li>
  </ul>

</nav>
<div id="main">
  <div class="container-fluid">
    <main role="main">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
